<template>
  <div id="user-list">
    <document-list-add-new
      v-model="isAddNewDocumentTemplateSidebarActive"
      @refetch-data="fetchDocuments"
    ></document-list-add-new>

    <h2 v-t="'documentTemplates'" class="text-2xl font-weight-semibold mb-4" />
    <v-card class="mb-12">
      <v-card-title v-t="'searchAndFilter'" />
      <v-row class="px-2 ma-0">
        <v-col cols="12" sm="4">
          <v-select
            v-model="moduleFilter"
            :placeholder="$t('selectModule')"
            :items="choices.moduleChoices"
            outlined
            dense
            clearable
            multiple
            hide-details
            :item-text="(item) => $t(item.text)"
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          :placeholder="$t('search')"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
          @keydown="delaySearchHandler"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewDocumentTemplateSidebarActive = !isAddNewDocumentTemplateSidebarActive"
          >
            <v-icon class="me-2">{{ icons.mdiPlus }}</v-icon>
            <span v-t="'addNewDocumentTemplate'" />
          </v-btn>
        </div>
      </v-card-text>

      <v-data-table
        :headers="tableColumns"
        :items="documents"
        :options.sync="options"
        :loading="loading"
        :server-items-length="totalDocumentListTable"
        :mobile-breakpoint="0"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPage'),
        }"
      >
        <template #[`top`]>
          <v-dialog v-model="isDialogVisible" max-width="650px" persistent>
            <v-card class="pa-sm-10 pa-3">
              <v-card-title class="justify-center text-h5"> Delete document template </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="d-flex align-center justify-center">{{
                    $t('confirmationModalUndoneQuestion')
                  }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-3">
                    <v-btn v-t="'discard'" outlined class="me-3" @click="discardDelete" />
                    <v-btn v-t="'delete'" color="error" @click="confirmDelete" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template #[`header.name`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.module`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.updated_at`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.actions`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`item.name`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-document-templates-view', params: { id: item.id } }"
                class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.module`]="{ item }">
          <v-chip
            label
            small
            :color="resolveDocumentModuleIcon(item.module).color"
            :class="`v-chip-light-bg ${
              resolveDocumentModuleIcon(item.module).color
            }--text font-weight-semibold text-capitalize ma-1 mb-2 ml-0`"
          >
            {{ $t(resolveDocumentModuleIcon(item.module).text) }}
          </v-chip>
        </template>

        <template #[`item.updated_at`]="{ item }">
          {{ formatDateToMonthShort(item.updatedAt) }}
        </template>

        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <v-tooltip bottom>
              <template #activator="tooltipActivator">
                <v-btn
                  icon
                  small
                  color="error"
                  v-bind="tooltipActivator.attrs"
                  v-on="tooltipActivator.on"
                  @click="deleteItem(item.id)"
                >
                  <v-icon size="18">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'delete'" />
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" v-on="on" @click="duplicateHandler(item.id)">
                  <v-icon size="18">
                    {{ icons.mdiContentDuplicate }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'duplicate'" />
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  :to="{ name: 'apps-document-templates-view', params: { id: item.id } }"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'view'" />
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiPlus, mdiDeleteOutline, mdiEyeOutline, mdiContentDuplicate } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { formatDateToMonthShort } from '@core/utils/filter'
import { useActions } from 'vuex-composition-helpers'

import DocumentListAddNew from './DocumentListAddNew.vue'
import useDocumentList from './useDocumentList'

export default {
  components: {
    DocumentListAddNew,
  },
  setup() {
    const isDialogVisible = ref(false)
    const isAddNewDocumentTemplateSidebarActive = ref(false)
    const itemToDeleteId = ref(null)

    const { deleteDocumentTemplate, duplicateDocumentTemplate } = useActions('templates', [
      'deleteDocumentTemplate',
      'duplicateDocumentTemplate',
    ])

    const deleteItem = (itemId) => {
      itemToDeleteId.value = itemId
      isDialogVisible.value = true
    }

    const {
      fetchDocuments,
      resolveDocumentModuleIcon,
      delaySearchHandler,

      tableColumns,
      searchQuery,
      totalDocumentListTable,
      loading,
      options,
      overview,
      documents,
      choices,
      moduleFilter,
    } = useDocumentList()

    const confirmDelete = async () => {
      if (itemToDeleteId.value !== null) {
        await deleteDocumentTemplate(itemToDeleteId.value)
        await fetchDocuments()
      }
      isDialogVisible.value = false
    }
    const discardDelete = () => {
      itemToDeleteId.value = null
      isDialogVisible.value = false
    }

    const duplicateHandler = async (templateId) => {
      await duplicateDocumentTemplate(templateId)
      await fetchDocuments()
    }

    return {
      fetchDocuments,
      resolveDocumentModuleIcon,
      deleteItem,
      confirmDelete,
      discardDelete,
      duplicateHandler,
      delaySearchHandler,

      isDialogVisible,
      itemToDeleteId,
      tableColumns,
      searchQuery,
      totalDocumentListTable,
      loading,
      options,
      isAddNewDocumentTemplateSidebarActive,
      overview,
      documents,
      choices,
      moduleFilter,

      formatDateToMonthShort,

      // icons
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiContentDuplicate,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
