<template>
  <v-navigation-drawer
    :value="isAddNewDocumentTemplateSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.lgAndUp ? '40%' : '100%'"
    app
    @input="(val) => resetDocumentTemplateData(!val)"
  >
    <div class="drawer-header d-flex align-center">
      <span v-t="'addNewDocumentTemplate'" class="font-weight-semibold text-base text--primary" />
      <v-spacer></v-spacer>
      <v-btn icon small @click="resetDocumentTemplateData(true)">
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card-text>
        <v-subheader v-t="'basicData'" />
        <v-text-field
          v-model="documentTemplateData.name"
          outlined
          dense
          maxlength="100"
          :label="$t('name')"
          :placeholder="$t('name')"
          hide-details="auto"
          class="mb-3"
          :rules="[validators.required]"
        ></v-text-field>

        <v-autocomplete
          v-model="documentTemplateData.module"
          :label="$t('selectModule')"
          :placeholder="$t('selectModule')"
          :items="defaultChoices.moduleChoices"
          outlined
          dense
          hide-details="auto"
          class="mb-3"
          :rules="[validators.required]"
        ></v-autocomplete>

        <v-subheader v-t="'editor'" />

        <summer-note
          :key="rerenderSummernoteTrigger"
          :email-variables="documentVariablesSummernote"
          :custom-fields-variables="customFieldsVariables"
          :height="700"
          hide-link-and-image-button
          @ready="readySummerNote"
        />
      </v-card-text>
      <v-card-actions class="mt-3">
        <v-row class="overwritten-drawer-actions">
          <v-col cols="12" md="6">
            <v-btn
              v-t="'discard'"
              color="secondary"
              outlined
              type="reset"
              block
              @click="resetDocumentTemplateData(true)"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
              :disabled="!valid || !documentTemplateData.contentHtml.length"
              block
              :loading="loading"
            >
              {{ $t('add') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { required } from '@core/utils/validation'

import { mdiClose, mdiTrashCanOutline } from '@mdi/js'
import { ref, getCurrentInstance, watch } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import _ from 'lodash'
import SummerNote from '@/plugins/summer-note/SummerNote.vue'

import {
  DOCUMENT_EMPLOYEES_VARIABLES,
  DOCUMENT_HOUSES_SHINE_VARIABLES,
  DOCUMENT_PROJECTS_SHINE_VARIABLES,
  DOCUMENT_CANDIDATES_VARIABLES,
  parseCustomFieldsToVariables,
  DOCUMENT_CLIENTS_SHINE_VARIABLES,
  mapNestedModuleCustomFieldsToVariables,
} from '@/plugins/summer-note/variables'
import documentTemplateUtils from '@/utils/templates/document/documentTemplateUtils'

export default {
  components: {
    SummerNote,
  },
  model: {
    prop: 'isAddNewDocumentTemplateSidebarActive',
    event: 'update:is-add-new-document-template-sidebar-active',
  },
  props: {
    isAddNewDocumentTemplateSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const { MODULE_EMPLOYEES, MODULE_HOUSES, MODULE_PROJECTS, MODULE_CLIENTS, MODULE_CANDIDATES, defaultChoices } =
      documentTemplateUtils()
    const blankDocumentTemplateData = {
      name: '',
      module: null,
      contentHtml: '',
    }

    const { getCandidateCustomFields } = useActions('company', ['getCandidateCustomFields'])
    const { getHouseCustomFields, getProjectCustomFields, getClientCustomFields } = useActions('staff', [
      'getHouseCustomFields',
      'getProjectCustomFields',
      'getClientCustomFields',
    ])
    const { createDocumentTemplate } = useActions('templates', ['createDocumentTemplate'])

    const loading = ref(false)

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const documentTemplateData = ref(_.cloneDeep(blankDocumentTemplateData))

    const editor = ref(null)
    const rerenderSummernoteTrigger = ref(false)

    const documentVariablesSummernote = ref([])

    const customFields = ref([])
    const customFieldsVariables = ref([])

    const resetDocumentTemplateData = (resetData) => {
      if (resetData) {
        documentTemplateData.value = _.cloneDeep(blankDocumentTemplateData)
        resetForm()
        emit('update:is-add-new-document-template-sidebar-active', false)
      }
    }

    const readySummerNote = (initializedEditor) => {
      editor.value = initializedEditor
      editor.value.summernote('code', documentTemplateData.value.contentHtml)
      editor.value.$on('change', (content) => {
        documentTemplateData.value.contentHtml = content
      })
    }

    const onSubmit = async () => {
      if (valid.value && documentTemplateData.value.contentHtml.length) {
        loading.value = true
        await createDocumentTemplate({ payload: documentTemplateData.value })
        loading.value = false
        emit('refetch-data')
        resetDocumentTemplateData(true)
      } else {
        validate()
      }
    }

    watch(
      () => documentTemplateData.value.module,
      async (newValue, oldValue) => {
        if (newValue === MODULE_EMPLOYEES && oldValue !== MODULE_EMPLOYEES) {
          customFields.value = await getCandidateCustomFields()
          const houseCustomFields = await getHouseCustomFields()
          const projectCustomFields = await getProjectCustomFields()
          const clientCustomFields = await getClientCustomFields()

          documentVariablesSummernote.value = DOCUMENT_EMPLOYEES_VARIABLES(vm)
          customFieldsVariables.value = [
            ...parseCustomFieldsToVariables(customFields.value, newValue),
            ...mapNestedModuleCustomFieldsToVariables(houseCustomFields, MODULE_EMPLOYEES, MODULE_HOUSES, vm),
            ...mapNestedModuleCustomFieldsToVariables(projectCustomFields, MODULE_EMPLOYEES, MODULE_PROJECTS, vm),
            ...mapNestedModuleCustomFieldsToVariables(clientCustomFields, MODULE_EMPLOYEES, MODULE_CLIENTS, vm),
          ]

          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        } else if (newValue === MODULE_HOUSES && oldValue !== MODULE_HOUSES) {
          customFields.value = await getHouseCustomFields()
          documentVariablesSummernote.value = DOCUMENT_HOUSES_SHINE_VARIABLES(vm)
          customFieldsVariables.value = parseCustomFieldsToVariables(customFields.value, newValue)
          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        } else if (newValue === MODULE_PROJECTS && oldValue !== MODULE_PROJECTS) {
          customFields.value = await getProjectCustomFields()
          documentVariablesSummernote.value = DOCUMENT_PROJECTS_SHINE_VARIABLES(vm)
          customFieldsVariables.value = parseCustomFieldsToVariables(customFields.value, newValue)
          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        } else if (newValue === MODULE_CLIENTS && oldValue !== MODULE_CLIENTS) {
          customFields.value = await getClientCustomFields()
          documentVariablesSummernote.value = DOCUMENT_CLIENTS_SHINE_VARIABLES(vm)
          customFieldsVariables.value = parseCustomFieldsToVariables(customFields.value, newValue)
          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        } else if (newValue === MODULE_CANDIDATES && oldValue !== MODULE_CANDIDATES) {
          customFields.value = await getCandidateCustomFields()
          documentVariablesSummernote.value = DOCUMENT_CANDIDATES_VARIABLES(vm)
          customFieldsVariables.value = parseCustomFieldsToVariables(customFields.value, newValue)
          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        } else {
          documentVariablesSummernote.value = []
          customFieldsVariables.value = []
          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        }
      },
    )

    return {
      resetDocumentTemplateData,
      onSubmit,

      defaultChoices,
      form,
      loading,
      documentTemplateData,
      valid,

      // Summer note
      readySummerNote,
      rerenderSummernoteTrigger,
      editor,
      documentVariablesSummernote,
      customFieldsVariables,

      // validation
      validators: { required },
      icons: {
        mdiClose,
        mdiTrashCanOutline,
      },
    }
  },
}
</script>
