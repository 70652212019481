import { ref, watch } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'

export default function useDocumentList() {
  const documents = ref([])
  const choices = ref({})

  const moduleFilter = ref([])

  const tableColumns = [
    { text: 'name', value: 'name', class: 'ps-7 text-uppercase' },
    { text: 'updatedAt', value: 'updated_at', class: 'text-uppercase' },
    { text: 'module', value: 'module', class: 'text-uppercase', sortable: false },
    {
      text: 'actions',
      value: 'actions',
      align: 'center',
      sortable: false,
      class: 'text-uppercase',
    },
  ]

  const searchQuery = ref('')
  const totalDocumentListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortDesc: [true],
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
  })
  const overview = ref([])

  const { getDocumentTemplates, getDocumentTemplatesFilters } = useActions('templates', [
    'getDocumentTemplates',
    'getDocumentTemplatesFilters',
  ])

  const fetchDocumentTemplatesChoices = async () => {
    choices.value = await getDocumentTemplatesFilters({
      module: moduleFilter.value,
      search: searchQuery.value,
    })
  }

  const fetchDocuments = async () => {
    loading.value = true
    const { data, success } = await getDocumentTemplates({
      module: moduleFilter.value,
      search: searchQuery.value,
      page: options.value.page,
      pageSize: options.value.itemsPerPage,
      ordering: options.value.sortBy.map((el, index) => {
        const parsedEl = el.replaceAll('.', '__')

        return `${options.value.sortDesc[index] ? '-' : ''}${parsedEl}`
      }),
    })
    if (success) {
      if (Object.hasOwnProperty.bind(data)('results') && Object.hasOwnProperty.bind(data)('count')) {
        documents.value = data.results
        totalDocumentListTable.value = data.count
      } else {
        documents.value = data
        totalDocumentListTable.value = data.length
      }
      loading.value = false
    }
    await fetchDocumentTemplatesChoices()
  }

  const timer = ref(null)
  const delaySearchHandler = async () => {
    clearTimeout(timer.value)
    timer.value = setTimeout(async () => {
      await fetchDocuments()
    }, 1000)
  }

  watch([moduleFilter, options], async () => {
    await fetchDocuments()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveDocumentModuleIcon = (value) => {
    if (value === 'employees') return { text: 'employees', color: 'primary' }
    if (value === 'houses') return { text: 'houses', color: 'secondary' }
    if (value === 'projects') return { text: 'projects', color: 'success' }
    if (value === 'clients') return { text: 'clients', color: 'info' }
    if (value === 'candidates') return { text: 'candidates', color: 'warning' }

    return { text: '-/-', color: 'secondary' }
  }

  // *===============================================---*
  // *--------- Default email templats ------------------*
  // *===============================================---*

  return {
    fetchDocuments,
    resolveDocumentModuleIcon,
    fetchDocumentTemplatesChoices,
    delaySearchHandler,

    tableColumns,
    searchQuery,
    totalDocumentListTable,
    loading,
    options,
    overview,
    documents,
    choices,
    moduleFilter,
  }
}
